// @flow
import React from 'react'
import { useMemo } from 'react'
import styled from 'styled-components/macro'
import { useSelector } from 'react-redux'
import { TeamLayout } from './TeamLayout'
import { getCanvasId, useCanvasTeams } from './connect/selectors'
import { getMyTeam } from '../../store/selectors/jrPlusState/teams'
import type { ReduxStore } from '../../store/rootReducer'
import { isController } from '../AppSetup'
import {
	getCollaborativeCulminatingMomentStep,
	getDocumentIdBeingGraded,
	isTraining,
} from '../../store/stores/general'
import { COLLABORATIVE_CULMINATING_MOMENT } from '@mission.io/mission-toolkit/constants'
import { CreativeCanvasFullScreen } from './components/TeacherStation'
import { Wrapper as CanvasGridWrapper, getLayout } from './components/CanvasGrid'
import { TRAINING_CANVAS_PROPS } from './constants'
import { CanvasCardForWatchingEdits } from './components/TeacherStation/CanvasCardForWatchingEdits.jsx'

/**
 * The Creative Canvas Station. If the user is a teacher, displays TeacherCreativeCanvas
 */
export function CreativeCanvas(): React$Node {
	const canvasId = useSelector(getCanvasId)
	const studentTeam = useSelector(getMyTeam)
	const isTeacher = isController()
	const teams = useCanvasTeams()
	const documentIdBeingGraded = useSelector(getDocumentIdBeingGraded)
	const teamOfDocumentBeingGraded = useSelector(
		getTeamIdForDocument({ documentId: documentIdBeingGraded, canvasId })
	)
	const isTrainingMode = useSelector(isTraining)

	const gradingDocumentData: ?{| documentId: string, teamId: string |} = useMemo(() => {
		if (!teamOfDocumentBeingGraded || !documentIdBeingGraded) {
			return null
		}
		return {
			documentId: documentIdBeingGraded,
			teamId: teamOfDocumentBeingGraded,
		}
	}, [teamOfDocumentBeingGraded, documentIdBeingGraded])

	if (isTrainingMode && !isTeacher) {
		return (
			<Container>
				<HeaderWrapper />
				<TeamLayout {...TRAINING_CANVAS_PROPS} />
			</Container>
		)
	}

	if (!canvasId || (!isTeacher && !studentTeam) || !teams) {
		return null
	}
	if (!isTeacher) {
		const teamIdToUse = studentTeam?.id
		if (!teamIdToUse) {
			throw new Error('Could not get a valid team. This should be impossible')
		}
		const documentId = teams.find(({ teamId }) => teamId === teamIdToUse)?.documentId
		if (!documentId) {
			throw new Error('Could not get a valid document id. This should be impossible')
		}
		return (
			<Container>
				<TeamLayout
					{...{
						canvasId,
						...(gradingDocumentData || { teamId: teamIdToUse, documentId }),
						// when grading canvases, all students should see the contextual data for the team being graded
						shouldUseContextualDataForTeam: !!gradingDocumentData,
					}}
				/>
			</Container>
		)
	} else {
		return (
			<TeacherCreativeCanvas
				{...{
					canvasId,
					teams,
					gradingDocumentData,
				}}
			/>
		)
	}
}

/**
 * What the teacher sees instead of the Creative Canvas Station. Displays an overview of all canvases, or if it is time to grade,
 * displays the current canvas to grade.
 * @param props.canvasId - the id of the current creative canvas station
 * @param props.teams - the team ids and document ids for all teams with documents for the current canvas
 * @param props.gradingDocumentData - the document id and team id of the document being graded
 */
function TeacherCreativeCanvas({ canvasId, teams, gradingDocumentData }): React$Node {
	const [selectedTeamId, setSelectedTeamId] = React.useState()
	const collaborativeCulminatingMomentScreenStep = useSelector(
		getCollaborativeCulminatingMomentStep
	)
	const isCreativeCanvasStationActive = Boolean(canvasId)

	if (
		collaborativeCulminatingMomentScreenStep
			? collaborativeCulminatingMomentScreenStep?.status ===
			  COLLABORATIVE_CULMINATING_MOMENT.SCREEN_STATUS.EDITING
			: isCreativeCanvasStationActive
	) {
		if (teams.length === 0) {
			return (
				<HeaderWrapper style={{ height: '100%' }}>
					Canvas data is unavailable: <br />
					None of the students are connected.
				</HeaderWrapper>
			)
		}
		const layout = teams.length > 8 ? getLayout(teams.length) : { cols: 4, rows: 2 }
		return (
			<Container>
				<HeaderWrapper style={{ paddingBottom: '1vh' }}>
					Click on a canvas to see a team’s progress
				</HeaderWrapper>
				<CanvasGridWrapper $cols={layout.cols} $rows={layout.rows} className="pt-6">
					{teams.map(({ teamId, documentId }) => (
						<CanvasCardForWatchingEdits
							key={teamId}
							documentId={documentId}
							teamId={teamId}
							onClick={id => {
								setSelectedTeamId(id)
							}}
							selected={selectedTeamId === teamId}
							canvasId={canvasId}
						/>
					))}
				</CanvasGridWrapper>
			</Container>
		)
	} else if (
		[
			COLLABORATIVE_CULMINATING_MOMENT.SCREEN_STATUS.GRADING_ALL,
			COLLABORATIVE_CULMINATING_MOMENT.SCREEN_STATUS.GRADING_HIDDEN,
			COLLABORATIVE_CULMINATING_MOMENT.SCREEN_STATUS.GRADING_UNHIDDEN,
		].includes(collaborativeCulminatingMomentScreenStep?.status) &&
		gradingDocumentData
	) {
		return (
			<CreativeCanvasFullScreen
				documentId={gradingDocumentData.documentId}
				teamId={gradingDocumentData.teamId}
				canvasId={canvasId}
			/>
		)
	}
}

/**
 * getTeamIdForDocument - get the teamId for the current documentId
 *
 * @param {Object} args - the arguments
 * @param {?string} args.documentId - the documentId to get the team for
 * @param {?string} args.canvasId - the id of the canvas station which the document belongs
 *
 * @return {ReduxStore => ?string} - a selector callback to get the team associated with the document
 */
function getTeamIdForDocument({
	documentId,
	canvasId,
}: {
	documentId: ?string,
	canvasId: ?string,
}): ReduxStore => ?string {
	return (state: ReduxStore) => {
		if (!canvasId) {
			return null
		}
		const canvas = state.jrPlusState.stationData.creativeCanvas?.canvases[canvasId]
		if (!documentId || !canvas) {
			return null
		}
		const teamData = canvas.teams.find(team => team.document.id === documentId)
		return teamData?.teamId
	}
}

const Container = styled.div`
	height: 100%;
	max-height: 100%;
	padding: var(--spacing4x) var(--spacing2x);
	display: flex;
	flex-direction: column;
`

const HeaderWrapper = styled.h2`
	text-align: center;
	flex: 0 0 3vh;
	padding: 2vh;
	display: flex;
	align-items: center;
	justify-content: center;
	gap: 1em;
`
