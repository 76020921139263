import React from 'react'
import { CanvasGridItemWrapper } from '../CanvasGrid'
import { useTeam } from '../../../../store/selectors/jrPlusState/teams'
import Canvas from '../../Canvas'
import type { JuniorTeam } from '@mission.io/mission-toolkit'
import { CreativeCanvasFullScreen } from './CreativeCanvasFullScreen'

/* A card to display a single team's canvas within a grid of other canvases. Displays the canvas and the team name. Clicking on the card will select the canvas and cause it to be fullscreen.
 * @param {string} props.teamId the team id of the team whose canvas is being displayed
 * @param {string} props.documentId the documentId that represents the canvas created
 * @param {boolean} props.selected whether or not the canvas is selected
 * @param {(documentId: ?string) => void} props.onClick a callback that is called when the canvas is voted for
 */
export function CanvasCardForWatchingEdits({
	teamId,
	documentId,
	onClick,
	selected,
	canvasId,
}: {
	teamId: string,
	documentId: string,
	onClick: (?string) => void,
	selected: boolean,
	canvasId: string,
}): React$Node {
	const team = useTeam(teamId)
	return (
		<CanvasGridItemWrapper
			onClick={() => {
				onClick(teamId)
			}}>
			<TeamName team={team} />

			<Canvas
				documentId={documentId}
				readOnly
				className="[--border-radius:12px] [--inner-border-radius:8px]"
			/>
			{selected && (
				<CreativeCanvasFullScreen
					isOpen
					onClose={e => {
						e.stopPropagation()
						onClick(undefined)
					}}
					teamId={teamId}
					documentId={documentId}
					canvasId={canvasId}
				/>
			)}
		</CanvasGridItemWrapper>
	)
}

// Show Team name above the canvas
function TeamName({ team }: { team: ?JuniorTeam }): React$Node {
	if (!team) return null
	return (
		<div className="flex-1 items-center flex z-20 pb-2 rounded-lg">
			<img src={team.icon} className="size-6" alt="" />
			<h3 className="mr-8 ml-4 text-sm">{team.displayName}</h3>
		</div>
	)
}
