import React from 'react'
import JrFrame from './JrFrame'
import JrPlusFrame from './JrPlusFrame'
import ConnectionStatus from '../ConnectionStatus'
import type { MissionType } from '@mission.io/mission-toolkit'
import { MISSION_TYPES } from '@mission.io/mission-toolkit/constants'
import PauseScreen from '../PauseScreen'
import { LiteracyEvent } from '../LiteracyEvent'
import classnames from 'classnames'
import styled from 'styled-components/macro'
import { FRAME_WRAPPER_ID, MODAL_APP_ELEMENT_FOR_FRAME } from '../basics/ReactModal'
import { useSelector } from 'react-redux'
import {
	shouldShowPauseScreen,
	isMissionComplete as getIsMissionComplete,
	getStation,
	isTraining as getIsTraining,
} from '../../store/stores/general'
import Joyride from '../Joyride'
import PointsPinger from '../PointsPinger'

import { getIsCulminatingMoment } from '../../store/selectors/sharedSelectors'

import ClassQuestionView from '../ClassQuestion'
import FinalDisplay from '../FinalDisplay'
import juniorStationMap from '../../constants/stationMap'
import juniorPlusStationMap from '../../constants/juniorPlusStationMap'

import { CreativeCanvasDocumentProvider } from '../CreativeCanvas/connect/CreativeCanvasDocumentContext'
import { isWantingToRunMissionAsControl } from '../AppSetup.jsx'

/**
 * This component is the main view for the student's station. It contains the station content, the pause screen, connection status, and
 * several other parts of the mission such as the literacy event, training, and points pinger, the end of mission display, and Culminating Moment Views.
 * @param {MissionType} missionType the type of mission that is being run (K-3 or 4+)
 */
export function FramedStationContent({
	missionType,
	className,
	style,
}: {
	missionType: MissionType,
	className?: string,
	style?: {},
}): React$Node {
	const isPaused = useSelector(shouldShowPauseScreen)

	return (
		<CreativeCanvasDocumentProvider>
			<FrameWrapper
				className={classnames(
					className,
					missionType === MISSION_TYPES.K_THROUGH_3
						? 'bg-gradient-to-r from-[#0d3a58] to-[#3d357d]'
						: 'bg-[rgb(21,21,64)]'
				)}>
				{isPaused && <PauseScreen />}
				<LiteracyEvent />

				<ConnectionStatus />
				{/* These FullSize elements are used to give the modal a parent and an "app element" to attach to. */}
				<FullSize id={FRAME_WRAPPER_ID}>
					<FullSize id={MODAL_APP_ELEMENT_FOR_FRAME}>
						{missionType === MISSION_TYPES.FOUR_PLUS ? (
							<JrPlusFrame>
								<StationContent missionType={missionType} />
							</JrPlusFrame>
						) : (
							<JrFrame>
								<StationContent missionType={missionType} />
							</JrFrame>
						)}
					</FullSize>
				</FullSize>
			</FrameWrapper>
		</CreativeCanvasDocumentProvider>
	)
}

/**
 * Content that is will be displayed within the frame. This includes the Literacy Event, Joyride, and the station content.
 * @param {MissionType} missionType used to help determine which station to display.
 */
function StationContent({ missionType }: { missionType: MissionType }): React$Node {
	const isMissionComplete = useSelector(getIsMissionComplete)
	const station = useSelector(getStation)
	const isCulminatingMoment = useSelector(getIsCulminatingMoment)
	const isController = isWantingToRunMissionAsControl()
	const isTraining = useSelector(getIsTraining)
	const stationMap =
		missionType === MISSION_TYPES.K_THROUGH_3
			? juniorStationMap({ classNameVehicle: isController ? 'vehicle-controller' : '' })
			: juniorPlusStationMap

	return (
		<>
			<PointsPinger />
			{isMissionComplete ? (
				<FinalDisplay />
			) : isCulminatingMoment ? (
				<ClassQuestionView />
			) : !station ? (
				<h1>Preparing controls...</h1>
			) : (
				<>
					{isTraining && <Joyride />}
					{stationMap.hasOwnProperty(station) ? stationMap[station].component : null}
				</>
			)}
		</>
	)
}
const FrameWrapper = styled.div`
	position: relative;
	height: 100%;
	overflow: hidden;
	width: 100%;
`

const FullSize = styled.div`
	width: 100%;
	height: 100%;
`
