// @flow
import React from 'react'
import { useState } from 'react'
import styled from 'styled-components/macro'
import Rubric from '../CreativeCanvas/components/Rubric'
import { DataDisplay, useDoesDataExistForEntity } from '../CreativeCanvas/components/DataDisplay'
import { FaCheck, FaStar } from 'react-icons/fa'
import { useSelector } from 'react-redux'
import { getIssueContent } from '../CreativeCanvas/connect/selectors'
import { getCollaborativeCulminatingMomentScreen } from '../../store/stores/general'
import { isController } from '../AppSetup'
import { COLLABORATIVE_CULMINATING_MOMENT, TRAINING } from '@mission.io/mission-toolkit/constants'
import type { MediaModalOverrides } from '../ContextualDataCard'
import { getMyTeam } from '../../store/selectors/jrPlusState/teams'

const AVAILABLE_TABS = {
	RUBRIC: 'RUBRIC',
	DATA: 'DATA',
}

/**
 * Tabs - shows tabs for rubric and canvas data for the given canvasId
 *
 * @param {Object} props - the react props
 * @param {string} props.canvasId - the id of the canvas station to display tabs for
 * @param {string} props.teamId - the id of the team to show the tabs for
 * @param {?string} props.document? - the id of the document to show tabs for
 * @param props.shouldUseContextualDataForTeam if true, the contextual data shown will be the data for the team specified in `props.teamId` instead of the current user
 *
 * @return {React$Node}
 */
export default function Tabs({
	canvasId,
	teamId,
	documentId,
	className,
	mediaModalOverrides,
	shouldUseContextualDataForTeam,
}: {
	canvasId: string,
	teamId: string,
	documentId?: ?string,
	className?: string,
	mediaModalOverrides?: ?MediaModalOverrides,
	shouldUseContextualDataForTeam?: boolean,
}): React$Node {
	const [selectedTab, setSelectedTab] = useState<$Keys<typeof AVAILABLE_TABS>>(
		AVAILABLE_TABS.RUBRIC
	)
	const { criteria } = useSelector(getIssueContent({ canvasId, teamId })) || {}
	const collaborativeCulminatingMomentScreen = useSelector(getCollaborativeCulminatingMomentScreen)
	const isTeacher = isController()
	const isCreating =
		collaborativeCulminatingMomentScreen?.currentStep?.status ===
			COLLABORATIVE_CULMINATING_MOMENT.SCREEN_STATUS.EDITING ||
		collaborativeCulminatingMomentScreen?.currentStep?.status ===
			COLLABORATIVE_CULMINATING_MOMENT.SCREEN_STATUS.AWAITING_TEACHER_TO_CONTINUE
	const myTeam = useSelector(getMyTeam)
	const entity = shouldUseContextualDataForTeam || myTeam?.id !== teamId ? { teamId } : undefined
	const doesDataExist = useDoesDataExistForEntity(entity, canvasId)

	return (
		<TabWrapper
			{...{ className }}
			data-training-target={TRAINING.IDS.CREATIVE_CANVAS_RUBRIC_AND_DATA_EXPLAINED}>
			<TabList>
				<RequirementsTab onClick={() => setSelectedTab(AVAILABLE_TABS.RUBRIC)}>
					<FaCheck className="text-accent-green self-center" />
					<TabTitle>Rubric</TabTitle>
				</RequirementsTab>
				{doesDataExist && (
					<DataTab onClick={() => setSelectedTab(AVAILABLE_TABS.DATA)}>
						<FaStar color="var(--color-secondary)" />
						<TabTitle>Data</TabTitle>
					</DataTab>
				)}
			</TabList>
			<DataWrapper>
				{selectedTab === AVAILABLE_TABS.RUBRIC || !doesDataExist ? (
					<Rubric
						criteria={criteria}
						hideHeader={true}
						documentId={documentId}
						grades={
							documentId
								? collaborativeCulminatingMomentScreen?.scores?.documents?.[documentId]
								: null
						}
						currentStepStatus={
							collaborativeCulminatingMomentScreen?.currentStep?.status ??
							COLLABORATIVE_CULMINATING_MOMENT.SCREEN_STATUS.EDITING
						}
						isTeacher={isTeacher}
					/>
				) : null}
				{selectedTab === AVAILABLE_TABS.DATA && doesDataExist ? (
					<DataDisplay
						allowVote={isCreating && myTeam?.id === teamId}
						css="height: 100%; width: 100%;"
						smallScreenSize={'1000px'}
						canvasId={canvasId}
						hideHeader={true}
						mediaModalOverrides={mediaModalOverrides}
						entity={entity}
					/>
				) : null}
			</DataWrapper>
		</TabWrapper>
	)
}

const TabWrapper = styled.div`
	flex: 1 1 0;
	display: flex;
	flex-direction: column;
`

const TabList = styled.div`
	display: flex;
	flex-direction: row;
	gap: var(--spacing);
	width: 100%;
	flex: 0 1 auto;
`

const Tab = styled.div`
	color: white;
	background-color: grey;
	padding: var(--spacing);
	border-top-left-radius: var(--spacing);
	border-top-right-radius: var(--spacing);
	display: flex;
	gap: var(--spacing);
	cursor: pointer;
	flex: 1 1 auto;
`

const RequirementsTab = styled(Tab)`
	background-color: var(--color-primary-dark);
`

const DataTab = styled(Tab)`
	background-color: var(--color-primary-darker);
`

const TabTitle = styled.h4`
	margin: 0;
	font-weight: 400;
`

const DataWrapper = styled.div`
	min-height: 0;
	flex: 1 1 0;
`
