import React from 'react'
import { useSelector } from 'react-redux'
import 'styled-components/macro'
import { CanvasGrid } from '../CanvasGrid'

import {
	getConnectedStudents,
	getCollaborativeCulminatingMomentStep,
	getScreenCanvasId,
} from '../../../../store/stores/general'
import { COLLABORATIVE_CULMINATING_MOMENT } from '@mission.io/mission-toolkit/constants'
import { CanvasCardForVoting } from '../CanvasCardForVoting'

/**
 * The teacher station view to observe votes during a collaborative culminating moment.
 * @param {Array<{documentId: string, teamId: string}>} qualifiedDocuments
 * @returns {React$Node}
 */
export function ViewToObserveVotes({
	qualifiedDocuments,
}: {
	qualifiedDocuments: Array<{ documentId: string, teamId: string }>,
}): React$Node {
	const collaborativeCulminatingMomentScreenStep = useSelector(
		getCollaborativeCulminatingMomentStep
	)
	const canvasId = useSelector(getScreenCanvasId)
	const connectedStudents = Object.keys(useSelector(getConnectedStudents) ?? {})
	if (
		!canvasId ||
		!collaborativeCulminatingMomentScreenStep ||
		collaborativeCulminatingMomentScreenStep.status !==
			COLLABORATIVE_CULMINATING_MOMENT.SCREEN_STATUS.VOTING
	) {
		throw Error('This should be impossible: in the vote view but not on a voting screen.')
	}

	let studentsWhoHaveVoted = 0
	const votesPerDoc = {}
	connectedStudents.forEach(studentId => {
		const documentIdsVotedFor =
			collaborativeCulminatingMomentScreenStep.votes.students[studentId]?.documentIds
		if (collaborativeCulminatingMomentScreenStep.votes.students[studentId]?.documentIds?.length) {
			studentsWhoHaveVoted += 1
			const docVotedFor = documentIdsVotedFor[documentIdsVotedFor.length - 1]
			votesPerDoc[docVotedFor] ??= 0
			votesPerDoc[docVotedFor] += 1
		}
	})

	return (
		<div css="display: flex; flex-direction: column; overflow: auto;">
			<h2>
				{studentsWhoHaveVoted}/{connectedStudents.length} students have voted{' '}
			</h2>
			<CanvasGrid totalTeams={qualifiedDocuments.length}>
				{qualifiedDocuments.map(({ documentId, teamId }, index) => (
					<CanvasCardForVoting
						teamId={teamId}
						documentId={documentId}
						index={index}
						voteTotal={votesPerDoc[documentId] || 0}
					/>
				))}
			</CanvasGrid>
		</div>
	)
}
