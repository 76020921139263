import React, { useEffect, useState } from 'react'
import { getIsCreativeCanvasGradingStep } from '../../store/selectors/sharedSelectors'
import { getIssueContent } from './connect/selectors'
import Tabs from '../ClassQuestion/Tabs'
import { Prompt } from './components/Prompt'
import { useStationContainerDimensions } from '../../utility/hooks'
import Canvas from './Canvas'
import { useSelector } from 'react-redux'
import 'styled-components/macro'
import { useTeam } from '../../store/selectors/jrPlusState/teams'
import type { MediaModalOverrides } from '../ContextualDataCard'
import { ReactModal } from '../basics'

/**
 * The creative canvas station where students will be able to analyze data and collaborate to form multi-media solutions to mission issues.
 * @param props.shouldUseContextualDataForTeam if true, the contextual data shown will be the data for the team specified in `props.teamId` instead of the current user
 */
export function TeamLayout({
	canvasId,
	teamId,
	documentId,
	isTeacher = false,
	mediaModalOverrides,
	shouldUseContextualDataForTeam,
}: {
	canvasId: string,
	teamId: string,
	documentId: string,
	isTeacher?: boolean,
	mediaModalOverrides?: ?MediaModalOverrides,
	shouldUseContextualDataForTeam?: boolean,
}): React$Node {
	// const team = useTeam(teamId)
	const isGrading = useSelector(getIsCreativeCanvasGradingStep) ?? false
	const isReadOnly = isGrading
	const team = useTeam(teamId)

	const { prompt } = useSelector(getIssueContent({ canvasId, teamId })) || {}

	const { width: screenWidth, height: screenHeight } = useStationContainerDimensions() || {
		width: 700,
		height: 400,
	}
	const screenRatio = screenWidth / screenHeight

	const screenPercentageForCanvas = (() => {
		if (screenRatio < 1.5) {
			return 0.7
		} else if (screenRatio < 2) {
			return 0.6
		} else if (screenRatio < 2.5) {
			return 0.48
		} else {
			return 0.4
		}
	})()
	const [expanded, setExpanded] = useState(false)
	// There is already custom full screen logic for teacher being performed in a parent component for the teacher station
	const allowFullScreen = !isTeacher && !isReadOnly
	// reset expanded variable when/if we no longer allow the canvas to be expanded
	useEffect(() => {
		if (!allowFullScreen && expanded) {
			setExpanded(false)
		}
	}, [allowFullScreen, expanded])

	if (!canvasId) return null

	return (
		<div
			css={`
				min-height: 0; // Allow this to shrink to fit inside the parent
				height: 100%;

				padding: 0vh 3vw;
				margin-bottom: var(--spacing3x);
				display: grid;
				grid-template:
					'canvas right-col-header' auto
					'canvas right-col-tabs' 1fr
					/ ${screenPercentageForCanvas * 100}% 1fr;

				gap: var(--spacing2x);
			`}>
			<div className="justify-self-center w-full [grid-area:canvas] relative">
				{!expanded && (
					<Canvas
						key={isReadOnly ? 'read-only' : 'allow-write'}
						documentId={documentId}
						isTeacher={isTeacher}
						readOnly={isReadOnly}
						propsForFullScreen={
							allowFullScreen
								? {
										expanded,
										setExpanded,
								  }
								: {}
						}
					/>
				)}
			</div>
			<div className="[grid-area:right-col-header] flex flex-col gap-4">
				{!isTeacher && isGrading && (
					<div className="relative p-[2vh] bg-white/20 rounded-2xl">
						<h2 className="text-center">{team?.displayName} team</h2>
					</div>
				)}
				<Prompt content={prompt} />
			</div>
			<Tabs
				className="[grid-area:right-col-tabs]"
				canvasId={canvasId}
				documentId={documentId}
				teamId={teamId}
				mediaModalOverrides={mediaModalOverrides}
				shouldUseContextualDataForTeam={shouldUseContextualDataForTeam || isTeacher}
			/>

			<ReactModal
				className="mx-auto relative top-1/2 -translate-y-1/2"
				style={(() => {
					let width
					if (0.75 * screenWidth > screenHeight) {
						width = screenHeight * (4 / 3)
					} else {
						width = screenWidth
					}
					return { content: { width } }
				})()}
				overlayClassName="bg-black/50 fixed inset-0 z-10"
				isOpen={expanded}
				onClose={() => setExpanded(false)}>
				<div className="max-h-full w-full my-auto relative">
					<Canvas
						key={isReadOnly ? 'read-only' : 'allow-write'}
						documentId={documentId}
						isTeacher={isTeacher}
						readOnly={isReadOnly}
						propsForFullScreen={
							allowFullScreen
								? {
										expanded,
										setExpanded,
								  }
								: {}
						}
					/>
				</div>
			</ReactModal>
		</div>
	)
}
