// @flow
import config from '../config'
import { englishWords, googleAriaLabelSelector } from '../constants/translationConstants'
import type { Direction } from '@mission.io/mission-toolkit'
import { MIN_DISPLACEMENT_BEFORE_UPDATE } from '../constants'

/**
 * Returns a number between lower (inclusive) and upper (inclusive)
 */
export function random(
	lower: number,
	upper: number,
	{ decimal = false }: { decimal: boolean } = {}
): number {
	if (decimal) {
		return Math.random() * (upper - lower) + lower
	} else {
		return Math.floor(Math.random() * (upper - lower + 1)) + lower
	}
}

/**
 * Clamp the value between the inclusive given upper and lower limits.

 *
 * @param {number} value   The value to clamp between upper and lower
 * @param {number} lower   The lower limit. The return value will be greater than or equal to this value
 * @param {number} upper   The upper limit. The return value will be less than or equal to this value

 * @returns the value clamped between the upper and lower limit
 */
export function clamp(value: number, lower: number, upper: number): number {
	return Math.min(Math.max(value, lower), upper)
}

/**
 * Checks whether the given string only includes digits from 0 - 9
 *
 * @param {string} string	The string to test
 * @returns boolean
 */
export function onlyContainsNumbers(string: string): boolean {
	return /^\d+$/.test(string)
}

/**
 * The array reduce function implemented for object
 * @param  {Object}   object   The object
 * @param  {Function} callback The callback function
 */
export function reduceObject<U, T>(
	object: $ReadOnly<{ [string]: T }>,
	callback: (previousValue: U, currentValue: T, string) => U,
	initialValue: U
): U {
	let previousValue = initialValue

	for (let key in object) {
		if (object.hasOwnProperty(key)) {
			previousValue = callback(previousValue, object[key], key)
		}
	}

	return previousValue
}

/**
 * The array map function implemented for object, returning an array
 * @param {*} object
 * @param {*} callback
 */
export function mapObject<U, T>(object: { [string]: T }, callback: (T, string) => U): U[] {
	const arr = []
	for (let key in object) {
		if (object.hasOwnProperty(key)) {
			arr.push(callback(object[key], key))
		}
	}
	return arr
}

/**
 * Sorts the values of the object using the given comparator function. The result is the
 * sorted array of values from object
 * @param {Object} object The object whose values are to be sorted
 * @param {Function} callback The comparator function. Should return a negative number if the
 * 												first argument to this function should come first, or positive if it
 * 												should come second.
 * @return {Array} The sorted array of values from the object
 */
export function sortValues<S>(object: { [string]: S }, callback: (S, S) => number): S[] {
	const sortedValues: S[] = []
	for (let key in object) {
		if (!object.hasOwnProperty(key)) {
			continue
		}
		let inserted = false
		const currentValue = object[key]
		for (let i = 0; i < sortedValues.length; i++) {
			if (callback(currentValue, sortedValues[i]) < 0) {
				sortedValues.splice(i, 0, currentValue)
				inserted = true
				break
			}
		}
		if (!inserted) {
			sortedValues.push(currentValue)
		}
	}

	return sortedValues
}

/**
 * A function to map the values of object while preserving its keys.
 * @param  {Object}   object   The object
 * @param  {Function} callback The callback function
 */
export function mapValues<U, T>(
	object: { +[string]: T },
	callback: (currentValue: T, string) => U
): { [string]: U } {
	const mappedObject: { [string]: U } = {}

	for (let key in object) {
		if (object.hasOwnProperty(key)) {
			mappedObject[key] = callback(object[key], key)
		}
	}

	return mappedObject
}

/**
 * The array forEach function implemented for object
 * @param {*} object
 * @param {*} callback
 */
export function forEachObject<T>(object: { [string]: T }, callback: (T, string) => mixed): void {
	for (let key in object) {
		if (object.hasOwnProperty(key)) {
			callback(object[key], key)
		}
	}
}

/**
 * Checks if the argument is an object
 */
export function isObject(item: mixed): boolean %checks {
	return !!item && typeof item === 'object' && !Array.isArray(item)
}

/**
 * get an array of `num` elements from 0 to num - 1
 *
 * @param {number} num  the number of elements to get
 *
 * @return {[number]} an array containin the numbers 0 to num - 1
 **/
export function range(num: number): Array<number> {
	let result = []
	for (let i = 0; i < num; i++) {
		result.push(i)
	}
	return result
}

/**
 * Test whether any value in an object passes the test implemented by the provided function
 * @param  {Object}   object   The object
 * @param  {Function} callback The callback function
 */
export function someEntry<T>(object: { [string]: T }, callback: (T, string) => boolean): boolean {
	for (let key in object) {
		if (object.hasOwnProperty(key)) {
			if (callback(object[key], key)) {
				return true
			}
		}
	}

	return false
}

/**
 * Finds the first entry that passes the test implemented by the provided function
 * @param {Object} object The object containing the entries to be tested
 * @param {Function} callback The callback function
 */
export function findEntry<T>(
	object: { [string]: T },
	callback: (T, string) => boolean
): ?[string, T] {
	for (let key in object) {
		if (object.hasOwnProperty(key)) {
			if (callback(object[key], key)) return [key, object[key]]
		}
	}
	return null
}

/**
 * Test whether all values in an object pass the test implemented by the provided function
 * @param  {Object}   object   The object
 * @param  {Function} callback The callback function
 */
export function everyEntry<T>(object: { [string]: T }, callback: (T, string) => boolean): boolean {
	for (let key in object) {
		if (object.hasOwnProperty(key)) {
			if (!callback(object[key], key)) {
				return false
			}
		}
	}
	return true
}

/**
 * The array filter function implemented for object. The resulting object from
 * this function will have all entries from the original object that pass the test
 * provided by callback.
 * @param  {Object}   object   The object
 * @param  {Function} callback The callback function
 * @return {Object} filtered object
 */
export function filterObject<T>(
	object: { [string]: T },
	callback: (currentValue: T, string) => boolean
): { [string]: T } {
	const filteredObject: { [string]: T } = {}
	for (let key in object) {
		if (object.hasOwnProperty(key) && callback(object[key], key)) {
			filteredObject[key] = object[key]
		}
	}
	return filteredObject
}

/**
 * Gets the sum of the results of calling `accessor` on each value in `object`.
 *
 * @param { {[string]: T} } object The object whose values will be used to find the sum
 * @param { (T) => number } accessor An accessor function that, given one of the values of `object`, returns a number.
										This number will be added to the final sum.
 */
export function getSum<T>(object: { [string]: T }, accessor: T => number): number {
	let sum = 0
	forEachObject(object, (value: T) => {
		sum += accessor(value)
	})
	return sum
}

/**
 * Gets either the plural or singular form of a word based on the amount passed in.
 * @param {string} word
 * @param {number} amount
 */
export function pluralize(word: string, amount: number): string {
	if (word === 'is') {
		return amount !== 1 ? 'are' : word
	} else {
		return amount !== 1 ? word + 's' : word
	}
}

/**
 * Object.values function, but with the flow type maintained.
 */
export function values<T>(object: { [string]: T }): T[] {
	const values = []

	for (let key in object) {
		if (object.hasOwnProperty(key)) {
			values.push(object[key])
		}
	}

	return values
}

/**
 * Given x and y and a point to base off of (c as a cartesian coordinate), this function will return the angle from the xy point to the center
 * @param {number} x
 * @param {number} y
 * @param {number} c
 * @return number
 */
export function getTheta(x: number, y: number, c: { x: number, y: number }): number {
	const val = ((Math.atan2(y - c.y, x - c.x) * 180) / Math.PI) * -1
	if (val < 0) return 360 + val
	return val
}

/**
 * Gets words by position on a text to text to speech.
 * @param {string} word
 * @param {number} position
 */
export function getWordsAt(
	word: string,
	position: number
): { before: string, word: string, after: string } {
	const string = String(word)
	const left = string.slice(0, position + 1).search(/\S+$/)
	const right = string.slice(position).search(/\s/)
	return {
		before: position > 0 ? string.slice(0, position - 1) + ' ' : '',
		word: right < 0 ? string.slice(left) : string.slice(left, right + position),
		after: right < 0 ? '' : ' ' + string.slice(left + right + 1),
	}
}

/**
 * Gets a formatted date to display
 * @param {Date} date
 * @returns {string} formatted as MM/DD/YYYY
 */
export function getFormattedDate(date: Date): string {
	var year = date.getFullYear()

	var month = (1 + date.getMonth()).toString()
	month = month.length > 1 ? month : '0' + month

	var day = date.getDate().toString()
	day = day.length > 1 ? day : '0' + day

	return month + '/' + day + '/' + year
}

export const isEnglishLanguage = (value: string): boolean => englishWords.indexOf(value) > -1

export const getLanguageCombo = (): ?HTMLSelectElement => {
	let languageCombo

	for (let i = 0; i < googleAriaLabelSelector.length; i++) {
		const ariaLabel = '[aria-label="' + googleAriaLabelSelector[i] + '"]'
		languageCombo = ((document.querySelector(ariaLabel): any): HTMLSelectElement)
		if (languageCombo) break
	}

	return languageCombo
}

/**
 * redirect the current page to dashboard. If `missionId` is provided, redirect to the survey and analytics page for the given missionId
 *
 * @param  {string} missionId - the id of the mission to show the survey for
 */
export function redirectToDashboard(missionId?: string) {
	let url = config.dashboardUrl
	if (missionId) {
		url += `/analytics?survey=${missionId}&mission=${missionId}`
	}
	window.location.href = url
}

/**
 * areDirectionsDifferentEnoughToSend - check if two directions are different enough to send an update to the server
 *
 * @param  {?Direction} direction1 - one of the directions to compare
 * @param  {?Direction} direction2 - the other direction to compare
 *
 * @returns boolean - true if the direction should be sent to the server, false otherwise
 */
export function areDirectionsDifferentEnoughToSend(
	direction1: ?Direction,
	direction2: ?Direction
): boolean {
	if (direction1 === direction2) {
		return false
	}
	if (Boolean(direction1) !== Boolean(direction2)) {
		return true
	}
	if (!direction1 || !direction2) {
		// The null checking is already handled in the conditions above, but flow can not follow it
		return false
	}
	const x1 = Math.cos(direction1.angle) * direction1.magnitude
	const y1 = Math.sin(direction1.angle) * direction1.magnitude

	const x2 = Math.cos(direction2.angle) * direction2.magnitude
	const y2 = Math.sin(direction2.angle) * direction2.magnitude

	return Math.sqrt((x1 - x2) ** 2 + (y1 - y2) ** 2) >= MIN_DISPLACEMENT_BEFORE_UPDATE
}

export const getPlatform = (): string => {
	// $FlowFixMe[prop-missing] userAgentData
	return navigator?.userAgentData?.platform || navigator?.platform || 'unknown'
}
